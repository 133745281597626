import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle:
      "SpotOn Case Study | Callie’s Hot Little Biscuit",
    pageDescription:
      "How a fast-growing quick serve business uses labor management software to save on labor cost and give their general managers time back.",
    title: 'Hot & ready without the hassle',
    subTitle: `How Callie’s Hot Little Biscuit streamlines payroll and labor management all from the SpotOn POS.`,
    businessName: "Callie’s Hot Little Biscuit",
    industry: "Restaurant, Quick Serve",
    location: "Charleston, South Carolina",
    products: [
      spotonProducts.LABOR_MANAGEMENT,
      spotonProducts.RESTAURANT,
      spotonProducts.SIDEKICK,
      spotonProducts.ORDER,
    ],
    description:
      "Callie’s Hot Little Biscuit serves handmade biscuits at two grab-and-go locations in addition to a significant catering operation, a food truck, wholesale, and nationwide shipping. Director of Operations Michael Lotz uses SpotOn Teamwork to create more efficient schedules, calculate tips in minutes, and navigate staffing challenges in a tough labor market.",
    quoteData: {
      imgName: "callies-hot-little-biscuit.png",
      quote: `“With SpotOn Teamwork, my store managers get two to three hours back that they can spend on themselves or with their family, rather than having to dive into something else at the store. When the work is done, I want them to be spending time with their families, doing whatever makes them happy, because that makes them better employees in the end.”`,
      personName: "Michael Lotz",
      personTitle: "Director of Operations, Callie’s Hot Little Biscuit",
    },
    goal: `Callie’s Hot Little Biscuit needed a POS system that integrated seamlessly with their accounting system and provided reporting accessible from anywhere, as well as direct online ordering and delivery to help retain profits.`,
    solution: `Since SpotOn Teamwork works in sync with their SpotOn Restaurant POS, Callie’s general managers and HR person get hours back in their weeks that they used to spend calculating tips and poring through spreadsheets. Employees clock-in on the POS, streamlining the clock-in process and providing Lotz with all the data he needs to make staffing decisions in one intuitive platform.`,
    results: {
      title: `The results`,
      stats: [
        { title: `2-3 Hours`, description: `saved per week for general managers` },
        {
          title: `6-8 Hours`,
          description: `saved per week for Human Resources staff`,
        },
        { title: `10%`, description: `Profits retained with direct ordering` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Opening a barbecue joint with smart tech",
        imageName: "barbwire.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-06-29",
        href: "/case-studies/barbwire-barbecue",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: '"Slow" food meets fast tech',
        imageName: "blue-barn.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-06-24",
        href: "/case-studies/blue-barn",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Selling more crepes with smarter data",
        imageName: "crepe-corner.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-04-21",
        href: "/case-studies/crepe-corner",
        goals: [
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.CONNECT_AND_SELL_ONLINE,
        ],
    },
  ];
  